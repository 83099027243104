.social-items {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: 0 auto;
  background-color: rgb(238, 233, 233);
  height: 3em;
  border-radius: 10px;
  margin-bottom: 0.3em;
  transition: transform 0.1s;

  &:hover {
    box-shadow: 0 1px 1px 1px rgba(black, 0.3);
    transform: translateY(-1px);
  }

  &:active {
    box-shadow: 1px 1px 1px rgba(black, 0.3);
    transform: translateY(1px);
  }

  @media only screen and (max-width: 1100px) {
    width: 90%;
  }

  a,
  a:link,
  a:link {
    text-decoration: none;
    color: black;
  }
  p {
    margin: 0;
    padding: 0;
  }
  &__item {
    display: flex;
    padding: 0.3em;
    align-items: center;
  }
}
