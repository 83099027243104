// $first-timing: 1s;
// $second-timing: $first-timing + 1s;
// $navBarHeight: 10em;



.promise-groups {
  text-align: center;
  padding: 0 10px;
}

.promise-groups-title {
  font-size: 2em;
  font-weight: bold;
  margin: 0.5em 0;

  @media screen and (max-width: 600px) {
    font-size: 1.5em;
    text-align: center;
    padding: 0 5vw;
  }
}

// .home-intro {
//   margin-top: 10em;
//   color: rgb(21, 21, 181);
//   opacity: 0;
//   width: 50%;
//   margin: 0 auto;
//   font-size: 2em;
//   animation-fill-mode: forwards;
//   position: relative;
// }

// .animation-1 {
//   animation-fill-mode: forwards;
//   animation-name: animation-1;
//   animation-duration: $first-timing;
//   opacity: 0;
//   animation-delay: 1.5s;
//   transform-origin: left;
// }

// .animation-2 {
//   animation-fill-mode: forwards;
//   animation-name: animation-2;
//   animation-duration: 1s;
//   animation-delay: 2s;
//   opacity: 0;
// }

// .animation-3 {
//   animation-name: animation-3;
//   animation-fill-mode: forwards;
//   animation-duration: 1s;
//   animation-delay: 5.5s;
//   opacity: 0;
//   // span {
//   //   display: inline-block;
//   // }
// }

// .animation-4 {
//   animation-fill-mode: forwards;
//   animation-name: animation-4;
//   animation-duration: $first-timing;
// }

// .animation-5 {
//   animation-fill-mode: forwards;
//   animation-name: animation-5;
//   animation-duration: $first-timing;
//   animation-delay: 0.5s;
// }

// .animation-6 {
//   animation-fill-mode: forwards;
//   animation-name: animation-6;
//   animation-duration: 3s;
//   animation-delay: 2.5s;
//   opacity: 0;
//   transform-origin: bottom left;
//   display: inline-block;
//   // span {
//   //   display: inline-block;
//   // }
// }

// // .anim {
// //   width: 1em;
// //   height: 1em;
// //   background-color: blue;
// //   margin: 1em;
// // }

// // .ani-async-1s {
// //   animation-name: ani-async;
// //   animation-duration: 1s;
// //   animation-timing-function: ease-in-out;
// //   animation-fill-mode: forwards;
// // }

// // .ani-async-2s {
// //   animation-name: ani-async;
// //   animation-duration: 2s;
// //   animation-timing-function: ease-in-out;
// //   animation-fill-mode: forwards;
// // }

// // .ani-async-3s {
// //   animation-name: ani-async;
// //   animation-duration: 3s;
// //   animation-timing-function: ease-in-out;
// //   animation-fill-mode: forwards;
// // }

// // .promise-all {
// //   border: 1px solid blue;
// //   padding: 2em;
// // }

// // .test {
// //   display: flex;
// //   flex-direction: column;
// //   align-items: center;
// //   flex-basis: 50%;
// // }

// // .result {
// //   display: flex;
// //   align-items: center;
// //   font-size: 1.5em;
// //   flex-basis: 50%;
// //   width: 250px;
// // }

// // .result-text {
// //   display: flex;

// //   flex-direction: column;
// //   align-items: center;
// // }

// // .example {
// //   display: flex;
// //   justify-content: center;
// // }

// // .anim-container {
// //   border: 1px solid green;
// //   padding: 1em;
// //   margin: 1em;
// //   min-width: 13em;
// // }

// @keyframes ani-async {
//   from {
//     transform: translateX(0);
//     background-color: blue;
//   }

//   to {
//     transform: translateX(130px);
//     background-color: red;
//   }
// }

// @keyframes animation-6 {
//   from {
//     opacity: 0;
//     transform: translateX(100%);
//   }

//   20% {
//     transform: translateX(0);
//   }
//   25% {
//     transform: translateX(0) rotate(100deg);
//   }
//   30% {
//     transform: translateX(0) rotate(82deg);
//   }

//   35% {
//     transform: translateX(0) rotate(98deg);
//   }
//   40% {
//     transform: translateX(0) rotate(84deg);
//   }
//   45% {
//     transform: translateX(0) rotate(96deg);
//   }
//   50% {
//     transform: translateX(0) rotate(86deg);
//   }

//   55% {
//     transform: translateX(0) rotate(94deg);
//     opacity: 1;
//   }

//   60% {
//     transform: translateX(0) rotate(88deg);
//     opacity: 1;
//   }

//   65% {
//     transform: translateX(0) rotate(90deg);
//     opacity: 1;
//   }

//   to {
//     transform: translateY(1500%) rotate(90deg);
//     opacity: 0;
//     display: none;
//     visibility: hidden;
//     color: red;
//   }
// }

// @keyframes animation-1 {
//   from {
//     transform: translateY(100%) rotate(90deg);
//     opacity: 0;
//   }

//   50% {
//     transform: translateY(0%) rotate(90deg);
//     opacity: 1;
//   }
//   to {
//     // font-streth: condensed;
//     transform: translateY(0);
//     opacity: 1;
//     color: red;
//   }
// }

// @keyframes animation-2 {
//   from {
//     transform: translateY(100%);
//     opacity: 0;
//   }
//   to {
//     // font-streth: condensed;
//     transform: translateY(0);
//     opacity: 1;
//   }
// }

// @keyframes animation-3 {
//   from {
//     position: absolute;
//     transform: translateX(100%);
//     opacity: 0;
//   }
//   to {
//     transform: translateX(-25%);
//     opacity: 1;
//     color: purple;
//     // position: relative;
//     // display: inline-block;
//   }
// }
// @keyframes animation-4 {
//   from {
//     transform: translateX(100%);
//     opacity: 0;
//   }
//   to {
//     transform: translateX(0);
//     opacity: 1;
//   }
// }
// @keyframes animation-5 {
//   from {
//     transform: translateX(-100%);
//     opacity: 0;
//   }
//   to {
//     transform: translateX(0);
//     opacity: 1;
//   }
// }
