.about {
  background: linear-gradient(to right, #ffe7e7, #f6c5db);
  padding: 2.5rem;
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  text-align: left;
  cursor: pointer; /* Indicate clickable area */

  .container {
    padding: 2rem;
    background: linear-gradient(to right, #f6c5db, #ffe7e7);
    display: flex;
    flex-direction: column;
  }
  .animation-status {
    align-self: flex-end;
    padding: 0.5rem;
    font-size: 0.8rem;
    color: #666;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: fit-content;
    background: linear-gradient(to right, #f6c5db, #ffe7e7);
  }

  // create an animation for the word restart

  .fade-in {
    opacity: 0;
    animation: fadeIn 1s ease-in forwards;
  }

  .delay-1 {
    animation-delay: 1s;
  }

  .staggered-list {
    list-style: none;
    padding: 0;
    line-height: 2;

    .list-item {
      display: block;
      opacity: 0;
      transform: translateX(50px);
      animation: slideIn 1s ease-out forwards;
      white-space: normal; /* Allow normal wrapping between words */
    }

    .no-break {
      display: inline-flex; /* Keep letters in a word together */
      white-space: nowrap; /* Prevent word splitting */
    }

    .letter {
      display: inline-block;
      opacity: 0;
      transform: translateX(20px) rotateZ(360deg);
      animation: spinAndSlideIn ease-out forwards;
    }

    .space {
      display: inline-block; /* Ensure space is treated as part of the layout */
      width: 0.3rem; /* Optional: adjust space width */
    }
  }

  &.animations-stopped {
    * {
      animation: none !important; /* Disable animations */
      transform: none !important; /* Reset transform properties */
      opacity: 1 !important; /* Ensure elements are fully visible */
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes spinAndSlideIn {
  0% {
    transform: translateX(20px) rotateZ(1080deg);
    opacity: 0;
  }

  10% {
    opacity: 1;
  }
  100% {
    transform: translateX(0) rotateZ(0deg);
    opacity: 1;
  }
}
