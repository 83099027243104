@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.course__filter {
  width: 50%;
  padding: 10px 15px;
  margin: 15px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.2s ease-in-out;

  &:focus {
    border-color: #0077b6;
  }
}

.course {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  justify-content: center;

  .course__card {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    height: 100%;
    transition: transform 0.3s, box-shadow 0.3s;
    text-align: center;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
    }

    .course__card__image__container {
      position: relative;
      overflow: hidden;
      height: 200px;
      padding: 1em;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-image: linear-gradient(135deg, #f093fb 0%, #f5576c 100%);
        // background-color: inherit;
        opacity: 0.1;
        z-index: 1;
        margin: 1em;
        border-radius: 10em;
      }

      .course__card__image__container__image {
        width: 100%;
        height: 100%;
        display: block;
        position: relative;
        z-index: 2;
        object-fit: contain;
        border-radius: 35%;
        // background-color: inherit;
      }
    }

    .course__card__name {
      padding: 16px;

      .course_card_link {
        font-size: 18px;
        font-weight: bold;
        text-decoration: none;
        color: #1a73e8;
        transition: color 0.3s;

        &:hover {
          color: #0d5bdc;
        }
      }
    }

    .course__card__description {
      font-style: italic;
      font-size: 16px;
      color: #333;
      padding: 0 16px 16px;
      flex-grow: 1;
      overflow-y: auto;
    }

    .course__card__institution {
      font-weight: bold;
    }
  }
}
