.tech-and-lang {
  width: 100%;

  .technologies,
  .languages {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: space-around;
    justify-content: space-around;

    .language-item,
    .tech-item {
      margin: 0.1em 0;
      border-radius: 5px;
      flex: 0 0 10%;
      align-self: center;
      padding: 0.2em 2em;
    }
  }
}
