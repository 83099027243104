.projects {
  & * {
    padding: 0;
    margin: 0;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0.1em;
  padding: 1em 0;
  align-content: center;

  &:not(:last-child) {
    border-bottom: 2px dashed grey;
  }

  &__technologies {
    text-align: center;
    display: flex;
    flex-direction: column;

    flex: 0 0 50%;

    &-list {
      display: flex;
      flex-wrap: wrap;
      align-content: center;

      li {
        flex: 1 0 25%;
        list-style: none;
        margin: 0.5em;
        padding: 0;
        margin: 0;
        background-color: #dddddd;
        border: 1px solid grey;
      }
    }
  }
  &__name {
    flex-basis: 30%;
    align-self: center;
  }

  &__maintaining {
    flex-basis: 20%;
    align-self: center;
  }

  &__details {
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media only screen and (max-width: 1000px) {
      flex-direction: column;
      // align-items: center;
    }
  }
}
