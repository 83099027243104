$first-timing: 1s;
$second-timing: $first-timing + 1s;
$navBarHeight: 10em;

.home-container {
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'left right right' 'left bottom bottom';
  column-gap: 10px;
  row-gap: 15px;
  background-color: orange;
  @media only screen and (max-width: 1000px) {
    top: 2em;
  }
}

.left {
  grid-area: left;
  .grid-item {
    background-color: green;
  }
}

.right {
  grid-area: right;
  .grid-item {
    background-color: yellow;
  }
}

.bottom {
  grid-area: bottom;
  .grid-item {
    background-color: blue;
  }
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  &.left {
    color: white;
    flex-direction: column-reverse;
  }
  &.right {
    color: darkorange;
  }
  &.bottom {
    flex-direction: column;
    color: lightgreen;
  }
}

.grid-item {
  display: flex;
  margin: 2px;
  flex: 1 1 32%;
  border: 1px solid red;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.home-title {
  color: rgb(21, 21, 181);
  opacity: 0;
  font-size: 2em;
  animation-fill-mode: forwards;
  @media only screen and (max-width: 1200px) {
    // margin-top: 10em;
  }
}

.home-intro {
  margin-top: 10em;
  color: rgb(21, 21, 181);
  opacity: 0;
  width: 50%;
  margin: 0 auto;
  font-size: 2em;
  animation-fill-mode: forwards;
  position: relative;
}

.animation-1 {
  animation-fill-mode: forwards;
  animation-name: animation-1;
  animation-duration: $first-timing;
  opacity: 0;
  animation-delay: 1.5s;
  transform-origin: left;
}

.animation-2 {
  animation-fill-mode: forwards;
  animation-name: animation-2;
  animation-duration: 1s;
  animation-delay: 2s;
  opacity: 0;
}
.animation-3 {
  animation-name: animation-3;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: 5.5s;
  opacity: 0;
  // span {
  //   display: inline-block;
  // }
}
.animation-4 {
  animation-fill-mode: forwards;
  animation-name: animation-4;
  animation-duration: $first-timing;
}
.animation-5 {
  animation-fill-mode: forwards;
  animation-name: animation-5;
  animation-duration: $first-timing;
  animation-delay: 0.5s;
}
.animation-6 {
  animation-fill-mode: forwards;
  animation-name: animation-6;
  animation-duration: 3s;
  animation-delay: 2.5s;
  opacity: 0;
  transform-origin: bottom left;
  display: inline-block;
  // span {
  //   display: inline-block;
  // }
}

.anim {
  width: 1em;
  height: 1em;
  background-color: blue;
  margin: 1em;
}

.ani-async-1s {
  animation-name: ani-async;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.ani-async-2s {
  animation-name: ani-async;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.ani-async-3s {
  animation-name: ani-async;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.promise-all {
  border: 1px solid blue;
  padding: 2em;
}

.test {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 50%;
}

.result {
  display: flex;
  align-items: center;
  font-size: 1.5em;
  flex-basis: 50%;
  padding: 1em;
  margin: 1em;
  border: 1px solid green;
  box-sizing: border-box;
  justify-content: center;
}

.result-text {
  display: flex;

  flex-direction: column;
  align-items: center;
}

.example {
  display: flex;
  justify-content: center;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    padding: 10px;
  }
}

.anim-container {
  border: 1px solid green;
  padding: 1em;
  margin: 1em;
  min-width: 13em;
}

.animation-ui-button {
  background-color: aqua;
  color: rgb(21, 21, 181);
  padding: 1em;
  border-radius: 0.5em;
  box-shadow: 1px 1px 1px black;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: rgb(21, 21, 181);
    color: aqua;
    box-shadow: 3px 3px 3px black;
    transform: translateY(-2px);
  }
  &:active {
    outline: none;
    transform: translateY(1px);
    box-shadow: 1px 1px 1px black;
  }
  &:disabled {
    background-color: grey;
    color: white;
    box-shadow: none;
    transform: none;
  }
}

@keyframes ani-async {
  from {
    transform: translateX(0);
    background-color: blue;
  }

  to {
    transform: translateX(130px);
    background-color: red;
  }
}

@keyframes animation-6 {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  20% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(0) rotate(100deg);
  }
  30% {
    transform: translateX(0) rotate(82deg);
  }

  35% {
    transform: translateX(0) rotate(98deg);
  }
  40% {
    transform: translateX(0) rotate(84deg);
  }
  45% {
    transform: translateX(0) rotate(96deg);
  }
  50% {
    transform: translateX(0) rotate(86deg);
  }

  55% {
    transform: translateX(0) rotate(94deg);
    opacity: 1;
  }

  60% {
    transform: translateX(0) rotate(88deg);
    opacity: 1;
  }

  65% {
    transform: translateX(0) rotate(90deg);
    opacity: 1;
  }

  to {
    transform: translateY(1500%) rotate(90deg);
    opacity: 0;
    display: none;
    visibility: hidden;
    color: red;
  }
}

@keyframes animation-1 {
  from {
    transform: translateY(100%) rotate(90deg);
    opacity: 0;
  }

  50% {
    transform: translateY(0%) rotate(90deg);
    opacity: 1;
  }
  to {
    // font-streth: condensed;
    transform: translateY(0);
    opacity: 1;
    color: red;
  }
}

@keyframes animation-2 {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    // font-streth: condensed;
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes animation-3 {
  from {
    position: absolute;
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(-25%);
    opacity: 1;
    color: purple;
    // position: relative;
    // display: inline-block;
  }
}
@keyframes animation-4 {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes animation-5 {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
