html {
  width: 100vw;
}

body {
  overflow-x: hidden;
}

.main-grid {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 2em;

  @media only screen and (max-width: 1200px) {
    width: 95%;
  }
}

.section {
  &__title {
    border-bottom: 1px solid grey;
  }
}
