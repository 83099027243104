.container {
  padding: 2rem;
  background: linear-gradient(to left, #e76363, #420d25);
  border-radius: 10px;
}

.skills-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 2rem; /* Updated padding */
  background: linear-gradient(
    to right,
    #e76363,
    #420d25
  ); /* New background color */
  margin: 0 0 0 10px;
  border-radius: 10px;
}

.skills-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 2rem; /* Updated padding */
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  background: linear-gradient(
    to right,
    #e76363,
    #420d25
  ); /* New background color */
  margin: 20px;
  text-align: left;
  min-width: 50%;
}

.skills-list {
  animation: slide-in 1s ease-in-out;
  padding: 2rem;
}

.skill-item {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  animation: slide-up 1s ease-in-out;
  padding: 1rem;
  border-radius: 5px;
}

@keyframes slide-in {
  from {
    transform: translateX(50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
