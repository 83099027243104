// * {
//   padding: 0;
//   margin: 0;
//   box-sizing: inherit;
// }

// html {
//   box-sizing: border-box;
// }

// .container {
//   width: 90em;
//   margin: 0 auto;
// }

// .header {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }

// .header > * {
//   flex-basis: 33%;
// }

// .courses > h2 {
//   // text-align: center;
//   border-bottom: 3px solid grey;
// }

// .header .social p {
//   padding: 0;
//   margin: 0;
// }

// .header .social {
//   text-align: right;
//   display: flex;
//   flex-direction: column;
// }

// .work {
//   display: flex;
//   flex-direction: column;
// }

.work-experience {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 1rem;

  &:not(:last-child) {
    border-bottom: 1px dotted grey;
  }

  & * {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
  }

  &__title {
    font-size: 1.5em;
    font-weight: 600;
    justify-self: flex-start;
  }

  &__date {
    text-align: right;
  }

  &__heading {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media only screen and (max-width: 1000px) {
      flex-direction: column;
    }

    & > * {
      flex-basis: 33.33%;
    }

    &-title {
      color: rgb(81, 81, 221);
      text-align: left;
      font-weight: 600;
    }

    &-company {
      text-align: center;
    }

    &-date {
      text-align: end;
    }
  }

  &__company p {
    font-size: 0.8em;
    font-weight: 300;
  }

  &__description {
    width: 100%;

    & ul {
      margin-top: 1em;
      font-size: 1.5em;
      margin-left: 2rem;
    }
  }
}

.slide-in-right {
  animation-name: slide-in-right;
  animation-duration: 1s;
}
.slide-in-left {
  animation-name: slide-in-left;
  animation-duration: 1s;
}

@keyframes slide-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slide-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
