.anim {
  width: 1em;
  height: 1em;
  background-color: blue;
  margin: 1em;
}

.anim-container {
  border: 1px solid green;
  padding: 1em;
  margin: 1em;
  min-width: 13em;
}
