.image-container {
  width: 7em;
  margin: 0.1em;
  animation: 1s linear 0s sliddy;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.image-book {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  border-radius: 1em;
}



@keyframes sliddy {
  from {
    transform: translateX(-150rem);
  }
  to {
    transform: translateX(0), skewX(25deg);
  }
}
