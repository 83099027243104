.homepage {
  text-align: center;
  overflow: hidden;
  padding: 0 20px;
  min-height: auto;
  width: 80%;
  margin: 0 auto;
}

/* Hero Section */
.hero {
  margin: 2rem 0;
  position: relative;
  display: flex;
  // align-items: center;
  justify-content: space-between;
  padding: 4rem 2rem;
  background: linear-gradient(to right top, #6a11cb, #2575fc);
  color: #fff;
  border-radius: 10px;

  /* Match initial animation state */
}

.hero-content h1 {
  background: linear-gradient(to left bottom, #6a11cb, #2575fc);
  border-radius: 10px;
  font-size: 3.5rem;
  padding: 1rem 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  justify-self: flex-start;
  /* Match initial animation state */
  opacity: 0; /* Start at the "from" state */
  transform: translateY(10px); /* Match fade-in "from" keyframe */

  animation: fade-in 3s ease-in-out 0.5s forwards; /* Plays once and stops */
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;

  /* Match initial animation state */
  opacity: 0; /* Start at the "from" state */
  transform: translateY(20px); /* Match slide-up "from" keyframe */

  animation: slide-up 3s ease-in-out 1s forwards; /* Plays once and stops */
}

.cta-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;

  /* Match initial animation state */
  opacity: 0; /* Start at the "from" state */
  transform: translateY(10px); /* Match fade-in "from" keyframe */

  animation: fade-in 3s ease-in-out 1.5s forwards; /* Plays once and stops */
}

.btn {
  padding: 0.8rem 2rem;
  font-size: 1rem;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn.primary {
  background: #fff;
  color: #2575fc;
  font-weight: bold;
}

.btn.secondary {
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
}

.btn:hover {
  transform: scale(1.05);
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.hero-image img {
  max-width: 400px;
  border-radius: 20px;

  /* Match initial animation state */
  transform: scale(0.8); /* Match zoom-in "from" keyframe */
  opacity: 0.8; /* Match zoom-in "from" keyframe */

  animation: zoom-in 3s ease-in-out 2s forwards; /* Plays once and stops */
}

/* Footer */
.footer p {
  margin: 0;

  /* Match initial animation state */
  opacity: 0; /* Start at the "from" state */
  transform: translateY(10px); /* Match fade-in "from" keyframe */

  animation: fade-in 3s ease-in-out 2.5s forwards; /* Plays once and stops */
}

/* Animations */
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes zoom-in {
  from {
    transform: scale(0.8);
    opacity: 0.8;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Pretty Section */
.pretty-section {
  position: relative;
  background: #f9f9f9;
  padding: 3rem 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin: 2rem 20px;
  text-align: left;
}

.pretty-section .content {
  max-width: 800px; /* Constrain the width */
  margin-left: auto;
  margin-right: auto;
}

.pretty-section h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333;
}

.pretty-section p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #555;
  margin: 1rem 0;
  transform: translateX(50px); /* Start slightly to the right */
  opacity: 0; /* Start hidden */
  animation: slide-left-fade 1s ease-out forwards;
}

.pretty-section p:nth-child(2) {
  animation-delay: 0.3s; /* Delay for the second paragraph */
}

.pretty-section p:nth-child(3) {
  animation-delay: 0.6s; /* Delay for the third paragraph */
}

/* Keyframes for Slide-In and Fade */
@keyframes slide-left-fade {
  from {
    transform: translateX(50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.mid-sections {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 2rem;

  & > :first-child {
    flex-basis: 70%;
  }

  & > :last-child {
    flex-basis: 30%;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
