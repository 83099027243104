.blog-post {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > * {
    flex-basis: 33%;
    text-align: center;
  }
}
