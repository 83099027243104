.educations-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  .education-data {
    padding: 0.5em;
    display: flex;
    flex-direction: column;
  }
}

.educations-container-print {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.educations {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: beige;
  padding: 0.5em;
  border-radius: 0.5em;
  margin: 0.3em;
  max-width: 33%;
  font-size: 0.8em;

  &:not(:last-child) {
    border-bottom: 1px dotted grey;
  }

  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }

  > * {
    flex-basis: 20%;
    text-align: center;
  }

  &__date {
    font-style: italic;
  }

  &__institution-link {
    justify-content: space-around;
    color: white;
    text-decoration: none;
    display: flex;
    padding: 0.2em;
    border-radius: 0.2em;
    align-items: center;

    @media only screen and (max-width: 1200px) {
      justify-content: center;
      flex-direction: column-reverse;
      align-items: center;
    }
    &:hover {
      background-color: rgb(242, 189, 233);
      .educations__title {
        background-color: rgb(173, 154, 191);
        transition: 1s all ease-in-out;
      }
    }
  }

  &__title {
    padding: 0.5em;
    background-color: rgb(117, 163, 160);
    border-radius: 1em;
    transition: 1s all ease-in-out;
  }

  &__image-container {
    max-height: 2em;
    max-width: 2em;
    padding-right: 0.5em;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    @media only screen and (max-width: 1200px) {
      display: flex;
    }
  }
}

.education__item {
  border: 1px solid black;
}
