$navbar-link-background: blue;

.navbar {
  display: flex;
  justify-content: space-between;
  background-color: black;
  padding: 0 2.5%;
  color: white;
  font-size: 1.5rem;

  @media screen and (max-width: 768px) {
    text-align: right;
  }

  &__logo {
    margin-right: auto;
    align-self: center;
  }

  &__items {
    display: flex;
    justify-content: space-between;
    & > * {
      padding: 1rem;
    }
    a {
      color: inherit;
      text-decoration: none;

      &:visited {
        color: inherit;
      }

      &:hover {
        text-decoration: none;
      }
    }

    @media only screen and (max-width: 1200px) {
      width: 95%;
      flex-direction: column;
    }
    &-link {
      text-decoration: none;
      // background-color: grey;
      color: white;
      padding: 1em;
      transition: all 1s;
      display: flex;
      justify-content: center;
      min-width: 5em;

      &:hover {
        background-color: grey;
      }

      a,
      a:link,
      a:visited {
        color: white;
        text-decoration: none;
      }
    }
  }
}
